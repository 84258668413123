import React from "react";
import { api } from "../../api/web";
import { Form } from "@procek69/nigriv-ui";

export default function ConfirmPhoneNumber() {

    let email = sessionStorage.getItem("email");
    if (!email) {
        window.location.assign('#/');
    }

    const ValidateChallenge = async data => {
        const { digits } = data;
        await api.ResetPassword({
            email,
            challenge: digits
        });
        sessionStorage.setItem("challenge", digits);
        window.location.assign("#/configured/sms-new-password");
    };

    return <>
        <div className="box">
            <h2>Resetowanie hasła</h2>
            <br />
            <p>Sprawdź otrzymany SMS. Podaj otrzymany kod.</p>
            <Form.Form onSubmit={ValidateChallenge} buttonText="Zatwierdź">
                <Form.Input label="Otrzymany kod" name="digits" />
            </Form.Form>
        </div>
    </>;
}