import React from "react";
import { useState, useEffect } from "react";

export default function List({ title, data, render = x => x, link = (item: any, key: number) => undefined, className = undefined, rowClassName = (key: number) => "nlist__element" }) {
    return <div className={className || "nlist"}>
        {title && <div className="nlist__title">{title}</div>}
        <div className="nlist__body">
            {(data || []).map((item, key) => {
                let linkData = link(item, key);
                if (linkData) {
                    return <a className="nlist__element is-link" key={key} {...linkData}>
                        {render(item)}
                    </a>
                }
                return <div className={rowClassName(key)} key={key}>
                    {render(item)}
                </div>
            })}
        </div>
    </div>;
}

export function Properties({ title, object = {}, columns = [], useDivider = true }) {
    return <List
        title={title}
        data={columns}
        render={(column: any) => {
            return <div className="nrow">
                <div>{column.name}</div>
                {useDivider && <div className="divider" />}
                <div>{column.render ? column.render(object) : object[column.key]}</div>
            </div>
        }} />
}

export function PropertiesEditor({ title, object = {}, columns = [], onChange, useDivider = true }) {

    const [item, setItem] = useState({});
    useEffect(() => {
        let nel = {};
        for (let el of columns) {
            nel[el.key] = object[el.key];
        }
        setItem(nel);
    }, [object]);

    let newColumns = columns.map((column, columnKey) => {
        return {
            name: column.name,
            render: _ => {
                let disabled = columns[columnKey].disabled;
                let className = "ninput";
                if (disabled) {
                    className += " disabled";
                }
                let value = item[column.key] || "";
                let changeDetected = e => {
                    let nel = { ...item };
                    nel[column.key] = e.target.value;
                    setItem(nel);
                    onChange && onChange(nel);
                };
                if (column.render) {
                    return column.render({
                        className, disabled, value, onChange: changeDetected
                    });
                }
                return <input className={className} disabled={disabled} type="text" value={value} onChange={changeDetected} />
            }
        };
    });

    return <Properties
        title={title}
        object={item}
        columns={newColumns}
        useDivider={useDivider}
    />
}

export function ListSelector({ title, options, onSelect, onConfirm, confirmText, defaultValue = -1 }) {

    const [selectedId, setSelectedId] = useState(-1);
    useEffect(() => {
        onSelect && onSelect(options[defaultValue]);
        setSelectedId(defaultValue);
    }, [defaultValue]);

    let it = 0;
    return <>
        <List
            title={title}
            data={options}
            className="nlist has-choices"
            rowClassName={key => {
                let isSelected = key == selectedId;
                return isSelected ? "nlist__element is-selected" : "nlist__element";
            }}
            render={x => {
                let current = it++;
                return <div onClick={_ => {
                    onSelect && onSelect(x);
                    setSelectedId(current);
                }}>
                    {x.render ? x.render() : <p>{x.name}</p>}
                </div>;
            }}
        />
        <br />
        {selectedId != -1 && <div className="nbuttons is_centered">
            <div>
                <button type="button" onClick={_ => onConfirm(options[selectedId])} className="nbutton">{confirmText}</button>

            </div>
        </div>}
    </>;
}