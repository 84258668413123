import React, { useEffect, useState } from "react";
import { api } from "../../api/web";
import { me } from "./../../utilities/jwt";

export default function ConfigureChoice() {

    const [canUseSMS, setCanUseSMS] = useState(false);

    const authenticate = async () => {
        try {
            const urlParams = new URLSearchParams(window.location.toString().split("?")[1]);
            if (urlParams.has("challenge") && urlParams.has("email")) {
                localStorage.setItem("email", urlParams.get("email"));
                const resp = await api.ConfirmEmail({
                    email: urlParams.get("email"),
                    challenge: urlParams.get("challenge"),
                });
                localStorage.setItem("token", resp.token);
                const stateOf = await api.AskForUser({ email: urlParams.get("email") });
                customizeUI && customizeUI(stateOf.settings);
            }
        } catch (e) {
            if (!localStorage.getItem("token")) {
                console.error(e);
            }
        }
        if (!localStorage.getItem("token")) {
            window.location.assign("#/");
        }
        setCanUseSMS(await api.CanResetViaSms());
    };

    useEffect(() => {
        authenticate();
    }, []);

    let _me = {};
    try {
        _me = me();
    } catch (e) {}

    return <>
        <div className="box">
            {_me.email && <h2 className="text-align-center">Witaj, {_me.email}!</h2>}
            <br />
            <br />
            <p className="text-align-center">Jak chcesz resetować swoje hasło?</p>
            <br />
            <br />
            <div className="nrow">
                {canUseSMS && <>
                    <div className="divider"></div>
                    <div>
                    <div className="option" onClick={_ => window.location.assign("#/configure/sms")}>
                        <img src="https://cdn.nigrivhub.com/reset/sms.svg" height="70px" alt="" />
                        <p>Wiadomość SMS</p>
                        <br />
                    </div>
                    <p></p>
                    </div>
                </>}
                <div className="hspace-10"></div>
                <div>
                    <div className="option" onClick={_ => window.location.assign("#/configure/app")}>
                        <img src="https://cdn.nigrivhub.com/reset/mobile-app.svg" height="70px" alt="" />
                        <p>Aplikacja w telefonie</p>
                        <br />
                    </div>
                    <p className="text-align-center recomendation">Rekomendowane</p>
                </div>
                <div className="divider"></div>
            </div>
            <br />
        </div>
    </>;
}