import React from "react";

export default function HGraph({ data, title, link = (item: any, key: number) => undefined, rowClassName = (key: number) => "nlist__element", maximum }) {

    let max = maximum || (data[0] || {}).value;
    for (let i = 1; i < data.length; i++) {
        if (max < data[i].value) {
            max = data[i].value;
        }
    }

    const render = item => {
        return <div className="nrow">
            <div className="col">
                {item.label}
            </div>
            <div>
                {item.value}&nbsp;
            </div>
            <div className="col">
                <div className="hgraph_progress" style={{ width: item.value / max * 100 + '%' }}></div>
            </div>
        </div>;
    };

    return <div className="hgraph">
        <div className="nlist">
            {title && <div className="nlist__title">{title}</div>}
            <div className="nlist__body">
                {(data || []).map((item, key) => {
                    let linkData = link(item, key);
                    if (linkData) {
                        return <a className="nlist__element is-link" key={key} {...linkData}>
                            {render(item)}
                        </a>
                    }
                    return <div className={rowClassName(key)} key={key}>
                        {render(item)}
                    </div>
                })}
            </div>
        </div>
    </div>;
}