import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function BetterTable({ className, columns, data, name, limit = 20, groupBy, disableFiltering, onRowClick, rowChildren = null }) {

    if (groupBy != undefined) {
        let props = {
            className, columns, data, name, limit, groupBy
        }
        return <GroupingBetterTable {...props} />
    }

    className = className || "ntable";
    columns.map(v => {
        v.sort = v.sort || ((a, b) => a && b ? a.localeCompare && b.localCompare ? a.localCompare(b) : `${a}`.localeCompare(`${b}`) : (a ? 1 : -1));
        return v;
    });
    const [sorting, setSorting] = useState([]);
    const [filtering, setFiltering] = useState(columns.map(_ => ""));
    const [useLimit, setUseLimit] = useState(-1);
    const { t } = useTranslation();
    let viewModel = data.map((row, rowKey) => columns.map(col => {
        if (typeof col.render === "function") return col.render(row, rowKey);
        return typeof col.show === "function" ? col.show(row) : row[col.key];
    }));

    useEffect(() => {
        setUseLimit(data.length > limit ? limit : -1);
    }, [data]);

    let sortData = (data) => {
        for (let i = 0; i < sorting.length; i++) {
            let key = sorting[i].key;
            let type = sorting[i].type;
            switch (type) {
                case "asc":
                    data = data.sort((a, b) => columns[key].sort(a[key], b[key]));
                    break;
                case "desc":
                    data = data.sort((b, a) => columns[key].sort(a[key], b[key]));
                    break;
            }
        }
        return data;
    }

    function stateOfSorting(item) {
        return sorting.filter(x => x.key == item.key)[0];
    }

    function iconForStateOfSorting(item) {
        let current = stateOfSorting(item);
        if (!current) return "⮃";
        switch (current.type) {
            case "asc":
                return "⭣";
            case "desc":
                return "🠡";
        }
        return "";
    }

    function toggleSort(key) {
        let current = stateOfSorting({ key });
        if (!current) {
            setSorting([...sorting, { key, type: "asc" }]);
            return;
        }
        let withoutCurrent = sorting.filter(x => x.key != current.key);
        if (current.type === "asc") {
            setSorting([...withoutCurrent, { key, type: "desc" }]);
            return;
        }
        setSorting([...withoutCurrent]);
    }

    function updateInput(key) {
        return (e) => {
            let newFiltering = [...filtering];
            newFiltering[key] = e.target.value;
            setFiltering(newFiltering);
        }
    }

    let filteringHasBeenUsed = filtering.filter(x => x || false).length > 0;

    let filterRow = (row, rowIndex) => {

        if (useLimit != -1 && !filteringHasBeenUsed) {
            if (rowIndex >= useLimit) {
                return false;
            }
        }
        for (let i = 0; i < filtering.length; i++) {
            let filter = filtering[i];
            filter = filter.toString().toLowerCase();
            if (filter && !row[i]) return false;
            if (filter && row[i] && row[i].toString().toLowerCase().indexOf(filter) == -1) {
                return false;
            }
        }
        return true;
    }

    return (
        <>
            <table className={className}>
                {name && <caption>{name}</caption>}
                <thead>
                    <tr>
                        {columns.map((col, key) => (
                            <th key={key}>
                                <div className="ntable__text-in-one-line" onClick={_ => toggleSort(key)}>
                                    <p>{col.name}</p>
                                    {!col.disableSorting && <span className="ntable__sort_icon">
                                        {iconForStateOfSorting({ key })}
                                    </span>}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {!disableFiltering && <tr>
                        {columns.map((col, key) => (
                            <td key={key} className="ntable-filter-input">
                                {col.filterable !== false && <input placeholder="🔎︎" type="text" value={filtering[key]} onChange={updateInput(key)} />}
                            </td>
                        ))}
                    </tr>}
                    {sortData(viewModel).map((row, rowKey) => {

                        if (filterRow(row, rowKey)) {
                            return <tr key={rowKey} onClick={e => onRowClick && onRowClick(data[rowKey], rowKey, e)}>
                                {columns.map((column, key) => (
                                    <td key={key} className={column.className || ""}>{row[key]}</td>
                                ))}
                            </tr>
                        }
                        return null;
                    })}
                    {rowChildren}
                </tbody>
            </table>
            {!filteringHasBeenUsed && useLimit != -1 && useLimit != data.length && <div className="ntable__loadmore" onClick={_ => setUseLimit(data.length)} />}
        </>
    )
}

export function GroupingBetterTable({ className, columns, data, name, limit = 20, groupBy, disableFiltering = false }) {
    className = className || "ntable";
    const [sorting, setSorting] = useState([{
        key: groupBy,
        type: "asc"
    }]);
    function iconForStateOfSorting(item) {
        let current = stateOfSorting(item);
        if (!current) return "⮃";
        switch (current.type) {
            case "asc":
                return "⭣";
            case "desc":
                return "🠡";
        }
        return "";
    }
    const [filtering, setFiltering] = useState(columns.map(_ => ""));
    const [useLimit, setUseLimit] = useState(-1);
    const [toggledRows, setToggledRows] = useState({});

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        let viewModel = data.map((row, rowKey) => columns.map(col => {
            if (typeof col.render === "function") return col.render(row, rowKey)
            return typeof col.show === "function" ? col.show(row) : row[col.key];
        }));

        let ngroups = {};
        for (let row of viewModel) {
            let currentGroup = row[groupBy];
            ngroups[currentGroup] = ngroups[currentGroup] || [];
            ngroups[currentGroup].push(row);
        }
        setGroups(Object.keys(ngroups).sort().map(key => {
            return {
                name: key,
                value: ngroups[key]
            };
        }));
    }, [data]);


    let canBeSorted = key => key != groupBy;

    function stateOfSorting(item) {
        return sorting.filter(x => x.key == item.key)[0];
    }

    function updateInput(key) {
        return (e) => {
            let newFiltering = [...filtering];
            newFiltering[key] = e.target.value;
            setFiltering(newFiltering);
        }
    }

    let filteringHasBeenUsed = filtering.filter(x => x || false).length > 0;
    let filterRow = (row, rowIndex) => {
        if (useLimit != -1 && !filteringHasBeenUsed) {
            if (rowIndex >= useLimit) {
                return false;
            }
        }
        for (let i = 0; i < filtering.length; i++) {
            let filter = filtering[i];
            filter = filter.toString().toLowerCase();
            if (filter && !row[i]) return false;
            if (filter && row[i] && row[i].toString().toLowerCase().indexOf(filter) == -1) {
                return false;
            }
        }
        return true;
    }

    function toggleSort(key) {
        let current = stateOfSorting({ key });
        if (!current) {
            setSorting([...sorting, { key, type: "asc" }]);
            return;
        }
        let withoutCurrent = sorting.filter(x => x.key != current.key);
        if (current.type === "asc") {
            setSorting([...withoutCurrent, { key, type: "desc" }]);
            return;
        }
        setSorting([...withoutCurrent]);
    }

    let lastDisplayedDistrinctValue = undefined;
    return (
        <>
            <table className={className}>
                {name && <caption>{name}</caption>}
                <thead>
                    <tr>
                        {columns.map((col, key) => (
                            <th key={key}>
                                <div className={canBeSorted(key) && "ntable__text-in-one-line"} onClick={_ => canBeSorted(key) && toggleSort(key)}>
                                    <p>{col.name}</p>
                                    {canBeSorted(key) && <span className="ntable__sort_icon">
                                        {iconForStateOfSorting({ key })}
                                    </span>}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {!disableFiltering && <tr>
                        {columns.map((col, key) => (
                            <td key={key} className="ntable-filter-input">
                                {col.filterable !== false && <input placeholder="🔎︎" type="text" value={filtering[key]} onChange={updateInput(key)} />}
                            </td>
                        ))}
                    </tr>}
                    {groups.map(({ name, value }, key) => {
                        return <>
                            <tr key={name + 'groupBy'} onClick={_ => {
                                let copy = { ...toggledRows };
                                if (toggledRows[key]) {
                                    copy[key] = false;
                                } else {
                                    copy[key] = true;
                                }
                                setToggledRows(copy);
                            }}>
                                <td colSpan={columns.length}>{name}</td>
                            </tr>

                            {toggledRows[key] && <BetterTableRows groupBy={groupBy} columns={columns} data={value} sorting={sorting} />}
                        </>
                    })}
                </tbody>
            </table>
            {!filteringHasBeenUsed && useLimit != -1 && useLimit != data.length && <div className="ntable__loadmore" onClick={_ => setUseLimit(data.length)} />}
        </>
    )
}

export function BetterTableRows({ columns, data, sorting, groupBy = -1 }) {

    function sortData(data) {

        for (let i = 0; i < sorting.length; i++) {
            let key = sorting[i].key;
            let type = sorting[i].type;
            let sortFunction = (columns[key] || {}).sort || ((a, b) => a && b ? a.localeCompare && b.localCompare ? a.localCompare(b) : `${a}`.localeCompare(`${b}`) : (a ? 1 : -1));
            switch (type) {
                case "asc":
                    data = data.sort((a, b) => sortFunction(a[key], b[key]));
                    break;
                case "desc":
                    data = data.sort((b, a) => sortFunction(a[key], b[key]));
                    break;
            }
        }

        return data;
    }

    return sortData(data).map((row, rowKey) => {
        return <>
            <tr key={rowKey}>
                {columns.map((_, key) => (
                    <td key={key}>{key != groupBy && row[key]}</td>
                ))}
            </tr>
        </>
    });
}