import React, { useState } from "react";

export default function Tabs({ tabsBodies = {}, labels = [] }) {

    const [currentIndex, setCurrentIndex] = useState(0);

    return <>
        <div className="ntabs">
            {labels.map((label, key) => {
                return <div className="ntabs__item" data-is-active={currentIndex == key} key={key} onClick={_ => setCurrentIndex(key)}>
                    {label}
                </div>;
            })}
        </div>
        {tabsBodies[currentIndex] ? tabsBodies[currentIndex] : null}
    </>;
}