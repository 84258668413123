import React, { useEffect, useState } from "react";
import { api } from "../../api/web";

export default function NotConfiguredMessage() {

    const [error, setError] = useState("");

    const sendEmail = async () => {
        let email = sessionStorage.getItem("email");
        if (!email) {
            window.location.assign("#/");
            return;
        }
        sessionStorage.removeItem("email");
        const result = await api.ConfirmEmailRequest({ email });
        if (result.error == "END_OF_LICENSE") {
            setError(result.error);
        }
    }

    useEffect(() => {
        sendEmail();
    }, []);

    let uiSettings = {};
    try {
        uiSettings = JSON.parse(localStorage.getItem("custom-ui"));
    } catch (e) {
        console.error(e);
    }

    return <>
        <div className="box">
        {uiSettings.logo && <>
        <br />
            <div className="nrow">
                <div className="divider"></div>
                <img width="250px" src={uiSettings.logo} alt="Logotyp Twojej organizacji" />
                <div className="divider"></div>
            </div>
            <br />
            </>}
            {!error && <>
                <h2>Potwierdź konto</h2>
                <br />
                <p>
                    Wygląda na to, że <b>jesteś tu pierwszy raz</b> i nikt z Twojej organizacji<br />
                    nie skonfigurował dla Ciebie sposobu resetowania hasła.<br />
                </p>
                <br />
                <p>
                    <b>Wysłaliśmy do Ciebie emaila</b>, dzięki któremu możesz<br />
                    samodzielnie skonfigurować reset.
                </p>
            </>}
            {error == "END_OF_LICENSE" && <p>
                W Twojej organizacji skonczyły się licencje i nie można już rejestrować<br />
                się w resecie hasła. Powiadom swojego administratora IT.<br />
            </p>}
            <br />
            <p>Możesz zamknąć to okno.</p>
        </div>
    </>;
}