import React from "react";
import { Fireworks } from 'fireworks-js/dist/react';

export default function ResetSuccess() {
    localStorage.clear();
    return <>
        <CustomFireworks />
        <div className="success text-align-center">Sukces!<br />Udało się!</div>
    </>;
}

function CustomFireworks() {
    const options = {
      speed: 3
    }
  
    const style = {
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      position: 'fixed',
      background: 'rgba(38,38,38,1)',
      zIndex: '1',
      boxShadow: 'inset 0 -50px 100px -20px rgba(0,0,0,.3)',
    }
  
    return <Fireworks options={options} style={style} />
  }