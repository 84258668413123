import React from "react";

interface ToolbarProps {
    children?: any;
    inverted?: boolean;
    className?: string;
    container?: boolean;
}
export function Toolbar(props: ToolbarProps) {
    let className = ["ntoolbar", props.inverted && "with-inverted", props.className].join(" ");
    if (props.container) {
        return <div className={className}>
            <div className="ncontainer">
                <div className="nrow">
                    {props.children}
                </div>
            </div>
        </div>
    }
    return <div className={className}>
        {props.children}
    </div>
}

export interface ToolbarFixedProps {
    children?: any;
    ommitFixedAfter?: boolean;
    container?: boolean;
}
export function ToolbarFixed(props: ToolbarFixedProps) {
    let className = "ntoolbar with-fixed-to-navbar";
    if (props.container) {
        return <>
            <div className={className}>
                <div className="ncontainer">
                    <div className="nrow">
                        {props.children}
                    </div>
                </div>
            </div>
            {!!!props.ommitFixedAfter && <div className="ntoolbar_with_fixed_to_navbar__cleaner" />}
        </>;
    }
    return <>
        <div className={className} {...props}>
            {props.children}
        </div>
        {!!!props.ommitFixedAfter && <div className="ntoolbar_with_fixed_to_navbar__cleaner" />}
    </>;
}