window.customizeUI = ({ background, color, logo, fgColor } = {}) => {
    localStorage.setItem("custom-ui", JSON.stringify({background, color, logo, fgColor }));
    let $background = document.querySelector("[data-custom-ui=background]");
    if ($background && background) {
        if (background.startsWith("http")) {
            $background.style.background = `url('${background}') no-repeat`;
            $background.style.backgroundSize = 'cover';
        }
        else {
            $background.style.background = background;
        }
    }

    let $logo = document.querySelector("[data-custom-ui=logo]");
    if ($logo && logo) {
        $logo.setAttribute("src", logo);
    }

    [...document.querySelectorAll("[data-custom-ui=color]")].forEach($tile => {
        if (color) $tile.style.background = color;
        if (fgColor) $tile.style.color = fgColor;
        [...$tile.querySelectorAll("*")].forEach($child => {
            if (fgColor) $child.style.color = fgColor;
        })
    });
}