import React from "react";

export default function ResetFailed() {
    return <>
        <div className="box">
            <p className="recomendation">Błąd</p>
            <h2>Reset nie powiódł się</h2>
            <br />
            <p>Nie udało się zresetować hasła.</p>
            <p>Reset nie zadziała, jeśli masz uprawnienia administratora w Office.</p>
            <br />
            <p>W innym przypadku zgłoś to działowi IT.</p>
        </div>
    </>;
}