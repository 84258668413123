import React from "react";

export default function Menu({ children, footer }) {
    return (
        <div className="nmenu">
            <main>
                {children}
            </main>
            <footer>
                {footer && footer()}
            </footer>
        </div>
    )
}