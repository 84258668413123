import { useEffect, useState } from "react";
import useHash from "./useHash";

export default function useHashActive({ to, exact }): [boolean, Function] {
    const [hash] = useHash();
    const [active, setActive] = useState(false);
    useEffect(() => {
        let href = `#${to}`;
        setActive((exact && hash === href) || (hash || "").startsWith(href));
    }, [hash]);
    return [active, setActive];
}