import React from "react";

export default function Checkbox(props) {
    let { value, onChange, title, readOnly } = props;
    return <div className="ncheckbox-row">
        <label className="ncheckbox-label">
            {title && <p>{title}</p>}
            <input type="checkbox" onChange={onChange} checked={value || false} readOnly={readOnly || false} />
            <span className="ncheckmark"></span>
        </label>
    </div>
}