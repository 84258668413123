import React, { useEffect, useState } from "react";
import { api } from "../../api/web";
import { Form } from "@procek69/nigriv-ui";

export default function ConfigureApp() {

    const [confirmPart, setConfirmPart] = useState(false);
    const [phoneDt, setPhoneDt] = useState();

    const init = async () => {
        await api.SetResetMethod({
            method: "SMS"
        });
    };

    const SetPhoneNumberRequest = async () => {
        if (!phoneDt[1]) return;
        await api.SetPhoneNumberRequest({
            phone_number: `+${phoneDt[0]}.${phoneDt[1]}`,
        });
        setConfirmPart(true);
    }

    const ValidatePhoneNumber = async (data) => {
        const { digits } = data;
        if (!digits) return;
        if (`${digits}`.length != 7) return;
        await api.ValidatePhoneNumber({
            challenge: digits,
            phone_number: `+${phoneDt[0]}.${phoneDt[1]}`,
        });
        window.location.assign("#/configure/success");
    }

    useEffect(() => {
        init();
    }, []);

    return <>
        <div className="nbuttons">
            <a href="#/configure/choice" className="link">🠔 Wróć do wyboru</a>
        </div>
        <p>
            <br />
        </p>
        <div className="box">
            <h2>Krok 1</h2>
            <br />

            {!confirmPart && <>
                <p>Podaj swój numer telefonu:</p>
                <Form.Form onSubmit={SetPhoneNumberRequest} buttonText="Zatwierdź">
                    <Form.PhoneNumber defaultPrefix="48" label="Numer telefonu" onChange={setPhoneDt} />
                </Form.Form>
            </>}
            {confirmPart && <>
                <p>Wysłano SMS na numer {phoneDt[1]}.</p>
                <p>Wpisz otrzymany kod:</p>
                <Form.Form onSubmit={ValidatePhoneNumber} buttonText="Zatwierdź">
                    <Form.Input label="7-cyfrowy kod" name="digits" />
                </Form.Form>
            </>}
        </div>
    </>;
}