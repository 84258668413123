import React from "react";

export function HModel(params) {
    return <HColumn {...params} />;
}

export function HColumn({ title, children, horizontal }) {
    return (
        <div className={`nhmodel-column ${horizontal ? 'horizontal' : ''}`}>
            {title && <HTitle title={title} />}
            {children && <HBody>{children}</HBody>}
        </div>
    );
}

export function HTitle({ title }) {
    return <div className="nhmodel-title">{title}</div>;
}

export function HBody({ children }) {
    return <div className="nhmodel-body">
        {children}
    </div>;
}

export function HItem({ children, className }) {
    return <div
        className={`nhmodel-item ${className}`}>
        {children}
    </div>;
}