import React, { useEffect, useState } from "react";
import { api } from "../../api/web";
import QRCode from 'qrcode.react';
import { Form } from "@procek69/nigriv-ui";

export default function ConfigureApp() {

    const [mfa, setMFA] = useState();

    const init = async () => {
        await api.SetResetMethod({
            method: "MS_AUTH"
        });
        const resp = await api.GetAuthenticatorToken({});
        setMFA(resp.mfa);
    };

    const onSubmit = async (data) => {
        if (!data.challenge) return;
        await api.ValidateMsAuthenticator(data);
        window.location.assign("#/configure/success");
    }

    useEffect(() => {
        init();
    }, []);

    return <>
        <div className="nbuttons">
            <a href="#/configure/choice" className="link">🠔 Wróć do wyboru</a>
        </div>
        <p>
            <br />
        </p>
        <div className="box">
            <h2>Krok 1</h2>
            <br />
            <p>Pobierz aplikację Authenticator na telefon.</p>
            <br />
            <div className="nrow">
                <div className="divider"></div>
                <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=pl&gl=US&utm_source=Nigriv%20Reset&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img height="55px" alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
                </a>
                <div className="divider"></div>
                <a
                    href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458?itsct=apps_box_badge&amp;itscg=30200"
                    style={{ display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "167px", height: "55px", padding: "8px" }}>
                    <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pl-pl?size=250x83&amp;releaseDate=1432944000&h=2e81a974cce9e371fc148a52ad3117e1"
                        alt="Download on the App Store"
                        style={{ borderRadius: "13px" }}
                    />
                </a>
                <div className="divider"></div>
            </div>
            <br />
            <h2>Krok 2</h2>
            <br />
            <p>Uruchom aplikację Authenticator i zeskanuj poniższy obrazek.</p>
            <br />
            {mfa && <>
                <div className="nrow">
                    <div className="divider"></div>
                    <QRCode value={mfa} />
                    <div className="divider"></div>
                </div>
            </>}
            <h2>Krok 3</h2>
            <br />
            <p>Wprowadź 6 cyfr, które wyświetliły się w aplikacji:</p>
            <Form.Form onSubmit={onSubmit} buttonText="Zatwierdź">
                <Form.Input label="6-cyfrowy kod" name="challenge" />
            </Form.Form>
        </div>
    </>;
}