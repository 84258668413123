import React, { useState, useEffect } from "react";

export function Kanban({ children }) {
    return <div className="kanban">
        <div className="kanban__body">
            {children}
        </div>
    </div>;
}

export function KanbanLane({ children = null, title, options }) {
    return <div className="kanban-lane">
        <div className="kanban-lane__top">
            <div className="nrow">
                <div className="kanban-lan__title">{title}</div>
                <div className="divider"></div>
                <div className="kanban-lan__options">{options}</div>
            </div>
        </div>
        <div className="kanban-lane__body">{children}</div>
    </div>;
}

export function KanbanCard({ className = "kanban-card", title, body, actions, author, value }) {
    return <div className={className}>
        <div className="nrow">
            <div className="kanbar-card__title">{title}</div>
            <div className="divider"></div>
            <div className="kanbar-card__value">{value}</div>
        </div>
        <div className="kanbar-card__body">{body}</div>
        <div className="nrow">
            <div className="kanbar-card__actions">{actions}</div>
            <div className="divider"></div>
            <div className="kanbar-card__author">{author}</div>
        </div>
    </div>;
}

interface Lane {
    name: string;
    renderOption: Function;
    filter: Function;
}

export function AutoKanban({ lanes = [], data = [] }) {

    const [indexData, setIndexData] = useState([]);

    useEffect(() => {
        setIndexData(lanes.map((el => {
            let nel = {...el};
            nel.items = data.filter(el.filter);
            nel.option = el.renderOption ? el.renderOption(nel, nel.items || []) : null;
            return nel;
        })))
    }, [lanes, data]);

    return <Kanban>
        {indexData.map((item, key) => {
            return <KanbanLane key={key} title={item.name} options={item.option}>
                {(item.items || []).map((item, key) => {
                    if (item.actions instanceof Function) {
                        item.actions = item.actions(item, key);
                    }
                    return <KanbanCard key={key} {...item} />
                })}
            </KanbanLane>;
        })}
    </Kanban>;
}