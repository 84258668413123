import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

export function SimpleLink ({ route, location, className, children, exact }) {

    let calc = () => true;
    if (exact) {
        calc = () => window.location.hash.substring(1) == route;
    } else {
        calc = () => window.location.hash.substring(1).startsWith(route);
    }

    let [isActive, setIsActive] = useState(calc());
    useEffect(() => {
        setIsActive(calc());
    }, [location])

    let elementClassName = className;
    if (isActive) {
        elementClassName = [elementClassName, "active"].join(' ');
    }

    return (
        <a href={['#', route].join('')} className={elementClassName}>{children}</a>
    )
}

export default withRouter(SimpleLink);