import React, { useState, useEffect } from "react";

export default function Select({ defaultValue, onChange, options = [], disabled }) {

    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return <select className="nselect" value={value} onChange={e => {
        setValue(e.target.value);
        onChange(e);
    }} disabled={disabled}>
        {options.map((item, key) => (
            <option key={key} value={item.value}>{item.label}</option>
        ))}
    </select>
}