import React, { useState, useEffect } from "react";

export function usePopup() {
    const [popup, setPopup] = useState(0);
    return [popup, x => setPopup(x || new Date().getTime()), _ => setPopup(1)];
}

export default function Popup({ children, title, popupHandler, hideClose = false, ignoreBackgroundClick = false }) {

    const [showTime, setShowTime] = useState(1);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (popupHandler >= showTime) {
            setShow(true);
            setShowTime(new Date().getTime());
        } else {
            setShow(false);
        }
    }, [popupHandler]);

    const close = () => {
        setShowTime(new Date().getTime());
        setShow(false);
    };

    return show ? <div className="npopup">
        <div className="npopup_background" onClick={() => {
            if (ignoreBackgroundClick) return;
            close();
        }}></div>
        <div className="npopup_body">
            <div className="npopup_body_wrapper">
                <div className="npopup_title">
                    <div className="nrow v-centered">
                        <p className="npopup_title_text">{title}</p>
                        <div className="divider"></div>
                        {!hideClose && <div className="nbutton small" onClick={close}>×</div>}
                    </div>
                </div>
                <div className="npopup_content_wrapper">
                    <div className="npopup_content">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </div> : <></>;
}