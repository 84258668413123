import React from "react";

export default function (props) {
    let className = ["text-tuple", props.className].join(' ');
    return (
        <div className={className}>
            <div className="text">{props.title}</div>
            <div className="text">{props.subtitle}</div>
            {props.children}
        </div>
    );
}