import React from "react";

export default function VGraph({ data, title, height = 100, link = (item: any, key: number) => undefined, rowClassName = (key: number) => "nlist__element" }) {

    let max = (data[0] || {}).value;
    for (let i = 1; i < data.length; i++) {
        if (max < data[i].value) {
            max = data[i].value;
        }
    }

    const render = item => {
        const h = Math.floor(item.value / max * height);
        const w = height - h;
        return <>
            <div className="vgraph_progress" style={{ height: w + 'px' }}></div>
            <div className="vgraph_progress" style={{ height: h + 'px' }}></div>
            <div>{item.value}</div>
            <div>{item.label}</div>
        </>;
    };

    return <div className="vgraph">
        <div className="nlist">
            {title && <div className="nlist__title">{title}</div>}
            <div className="nlist__body">
                {(data || []).map((item, key) => {
                    let linkData = link(item, key);
                    if (linkData) {
                        return <a className="nlist__element is-link" key={key} {...linkData}>
                            {render(item)}
                        </a>
                    }
                    return <div className={rowClassName(key)} key={key}>
                        {render(item)}
                    </div>
                })}
            </div>
        </div>
    </div>;
}