import API from "./api";
import ApiDriver from "./driver";

export default class WebApi extends API {
    constructor() {
        super();
        this._driver = new ApiDriver({ host: `api.${window.location.hostname}` });
    }
}

export const api = new WebApi();