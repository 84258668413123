import React, { useState } from "react";
import useHashActive from "../hooks/useHashActive";

export function NavbarFixed({ children, useFixedAfter = true, useContainer = true }) {

    const [showMenu, setShowMenu] = useState(false);

    return <>
        <Navbar className="with-fixed" useContainer={useContainer}>
            {children}
            <div className="navbarmenu__anchor">
                <NavbarLink onClick={_ => setShowMenu(!showMenu)} text="MENU" />
            </div>
        </Navbar>
        {showMenu && <>
            <NavbarFixedMenu>{children}</NavbarFixedMenu>
        </>}
        {useFixedAfter && <div className="nnavbar_with_fixed__cleaner" />}
    </>
}

export function NavbarFixedMenu({children}) {
    return <>
        <div className="nnavbar-fixed-menu-blur" />
        <div className="nnavbar-fixed-menu">
            {children}
        </div>
    </>;
}

export function Navbar({ children, className, useContainer }) {
    return <nav className={["nnavbar", className || ''].join(" ")}>
        {useContainer ? <div className="container">
            {children}
        </div> : children}
    </nav>;
}

export function NavbarLink(props: { href?: string, icon?: string, activeIcon?: string, path?: string, text?: string, iconPosition?: "left" | "right", onClick: any }) {
    if (props.href) {
        let icon = <NavbarIcon
            icon={props.icon}
            activeIcon={props.activeIcon}
            to={props.path}
        />;
        return <a href={props.href} className="nnavbar-link" onClick={props.onClick}>
            <div className="nnavbar-link-body">
                {props.icon && props.iconPosition != "right" && icon}
                {props.text && <p>{props.text}</p>}
                {props.icon && props.iconPosition == "right" && icon}
            </div>
        </a>;
    }
    let icon = <NavbarIcon
        icon={props.icon}
        activeIcon={props.activeIcon}
        to={props.path}
        exact
    />;
    return <NavLink exact to={props.path} className="nnavbar-link" activeClassName="focus" onClick={props.onClick}>
        <div className="nnavbar-link-body">
            {props.icon && props.iconPosition != "right" && icon}
            {props.text && <p>{props.text}</p>}
            {props.icon && props.iconPosition == "right" && icon}
        </div>
    </NavLink>;
}

export function NavLink(props) {
    const [active] = useHashActive({
        to: props.to,
        exact: props.exact,
    });
    return <a onClick={props.onClick} className={`${props.className}${active ? ` ${props.activeClassName}` : ''}`} href={`#${props.to}`}>
        {props.children}
    </a>
}

export function NavbarIcon(props) {
    if (!props.activeIcon) {
        return <img src={props.icon} height="22px" className={props.className} />;
    }
    let [active] = useHashActive({
        to: props.to,
        exact: props.exact,
    });
    return <img data-x={active} src={active ? props.activeIcon : props.icon} height="22px" />;
}

export function NavbarDivider() {
    return <div className="divider desktop-only"></div>;
}