import React, { useEffect, useState } from "react";
import useHash from "../hooks/useHash";

export default function Sidebar({ top, className, style, menu = [] }) {
    const [hash] = useHash();
    return (
        <div style={style} className={['nsidebar', className].join(' ')}>
            {top}
            <nav className="list-menu">
                {menu.map((item, key) => {
                    let isActive = hash.startsWith(item.href);
                    return <a href={item.href} key={key} className="list-menu-element">
                        <div className={`list-menu-element__indicator ${isActive && 'active'}`} />
                        <div className="list-menu-element__icon">
                            <img src={item.icon} width="22px" height="22px" alt="" />
                        </div>
                        <div className="list-menu-element__title">
                            {item.title}
                        </div>
                    </a>
                })}
            </nav>
        </div>
    )
}