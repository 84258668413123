import React from "react";

export default function Accent({ accentUrl, accentSize = 50, children, className }) {

    return (
        <div className={["naccent", className].join(' ')} style={{minHeight: accentSize*9/6}}>
            <img className="naccent__image" draggable={false} width={accentSize} src={accentUrl} unselectable="on" />
            {children}
        </div>
    );
}