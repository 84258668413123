import React from "react";
import BetterTable from "./table";

export default function EditingTable({ columns = [], data = [], name = "", onChange, deleteText, addText }) {

    if (columns.length == 0) {
        columns = [
            { name: "Klucz", key: "key" },
            { name: "Wartość", key: "value" },
        ];
    }

    if (deleteText && (columns.filter(x => x["_editingTableFilterKey"]).length == 0)) {
        columns.push({
            _editingTableFilterKey: true,
            key: "",
            disableSorting: true,
            render: (_, index) => {
                const removeElementAction = _ => {
                    let cdata = [...data];
                    cdata.splice(index, 1);
                    onChange(cdata);
                }
                return <p className="nlink" onClick={removeElementAction}>{deleteText}</p>
            },
        });
    }

    return <>
        <BetterTable
            columns={columns.map(col => {
                col.className = "editable";
                if (col.render) return col;
                col.render = (value = {}, index) => {
                    return <input type="text"
                        value={value[col.key] || ""}
                        onChange={e => {
                            data[index] = data[index] || {};
                            data[index][col.key] = e.target.value;
                            onChange && onChange(data);
                        }}
                    />
                }
                return col;
            })}
            className="ntable"
            data={data}
            name={name}
            groupBy={null}
            disableFiltering={true}
            onRowClick={null}
            rowChildren={<>
                {addText && <tr>
                    <td colSpan={columns.length}>
                        <div className="nbuttons">
                            <button className="nbutton primary" onClick={_ => {
                                onChange([...data, {}]);
                            }}>{addText}</button>
                        </div>
                    </td>
                </tr>}
            </>}
        />
    </>
}