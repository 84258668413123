import { useEffect, useState } from "react";

export default function useHash(): [string, Function] {
    const [hash, setHash] = useState(window.location.hash);
    useEffect(() => {
        const isSupported = window && window.addEventListener;
        if (!isSupported) return;
        const eventListener = _ => setHash(window.location.hash);
        window.addEventListener("hashchange", eventListener);
        return () => {
            window.removeEventListener("hashchange", eventListener);
        };
    }, []);
    return [hash, setHash];
}