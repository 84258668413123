import React from "react";

export default function MenuGrid({ children }) {
    return <>
        <div className="nmenugrid">
            {children}
        </div>
    </>;
}

export function MenuGridItem({ children, text, background, href, className }) {
    const RootTag = href ? 'a' : 'div';
    return <RootTag className={className || "nmenugrid__item"} href={href}>
        {background && <img className="nmenugrid__item__image" src={background} />}
        <p className="nmenugrid__item__body">
            <p className="nmenugrid__item__body__title">{text}</p>
            {children}
        </p>
    </RootTag>
}

export function MenuGridSection({ children }) {
    return <div className="nmenugrid__section">
        {children}
    </div>;
}