import React from "react";

export default function ListItem (props) {
    let className = ["list-item", props.className].join(' ');
    return (
        <div className={className}>
            <div className="row">
                {props.children}
            </div>
        </div>
    );
}