import React, { useState } from "react";
import { PivotTable, Checkbox } from "..";

export default function PivotCheckboxTable(props) {
    const [data, setData] = useState({});

    return (
        <PivotTable
            {...props}
            data={(x, y) => {
                return {
                    className: "no-margin",
                    show: () => <div className="nrow">
                        <div className="divider"></div>
                        <Checkbox value={(data[x] || {})[y]} onChange={async _ => {
                            let copy = { ...data };
                            copy[x] = copy[x] || {};
                            copy[x][y] = copy[x][y] || false;
                            copy[x][y] = !copy[x][y];
                            if (props.onChange) {
                                await props.onChange({
                                    x, y, value: copy[x][y]
                                });
                            }
                            setData(copy);
                        }} />
                        <div className="divider"></div>
                    </div>
                };
            }}
        />
    );
}