import React from "react";

export default function PivotTable(props: { columnsX: [{ name: string }], columnsY: [{ name: string }], data: Function, onClick?: Function }) {

    let { columnsX, columnsY, data, onClick } = props;

    return <>
        <div className="nscrollable">
            <table className="ntable pivot">
                <thead>
                    <tr>
                        <th></th>
                        {columnsY.map((el, key) => <th key={key}>{el.name}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {columnsX.map((el, x) => {
                        return <tr key={x}>
                            <th>{el.name}</th>
                            {columnsY.map((_, y) => {
                                let item = data(x, y);
                                return <td key={y} onClick={_ => onClick && onClick({ x, y, item })} className={item.className}>{item.show ? item.show() : item}</td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </>;
}