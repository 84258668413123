import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import StartForm from './client_steps/StartForm';
import NotConfiguredMessage from "./client_steps/NotConfiguredMessage";
import ConfigureSMS from "./client_steps/ConfigureSMS";
import ConfigureApp from "./client_steps/ConfigureApp";
import ConfigureChoice from "./client_steps/ConfigureChoice";
import EndOfPhoneNumber from './client_steps/EndOfPhoneNumber';
import ConfirmPhoneNumber from './client_steps/ConfirmPhoneNumber';
import ResetSuccess from "./client_steps/ResetSuccess";
import ConfirmMsAuth from './client_steps/ConfirmMsAuth';
import NewPassword from "./client_steps/NewPassword";
import ResetFailed from "./client_steps/ResetFailed";

export default function IndexApp() {
    return <>
        <HashRouter>
            <Switch>
                <Route exact path="/" component={StartForm} />
                
                <Route exact path="/not-configured/message" component={NotConfiguredMessage} />
                <Route exact path="/configure/choice" component={ConfigureChoice} />
                <Route exact path="/configure/sms" component={ConfigureSMS} />
                <Route exact path="/configure/app" component={ConfigureApp} />
                <Route exact path="/configure/success" component={ResetSuccess} />

                <Route exact path="/configured/end-of-phone-number" component={EndOfPhoneNumber} />
                <Route exact path="/configured/confirm-phone-number" component={ConfirmPhoneNumber} />
                <Route exact path="/configured/sms-new-password" component={NewPassword} />
                <Route exact path="/configured/success" component={ResetSuccess} />
                <Route exact path="/configured/failed" component={ResetFailed} />

                <Route exact path="/configured/confirm-ms-auth" component={ConfirmMsAuth} />
                <Redirect to="/" />
            </Switch>
        </HashRouter>
    </>;
}