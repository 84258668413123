import React, { useState, useEffect } from "react";

export function encodeSign(item) {
    return item.map(item => item ? `${parseInt(item.x)} ${parseInt(item.y)}` : -1).join(' ')
}
export function decodeSign(data) {
    let objectAgain = [];
    let x = false;
    let tmp;
    data.split(" ").forEach(item => {
        if (item == -1) {
            objectAgain.push(undefined);
            return;
        }
        if (!x) {
            x = true;
            tmp = { x: parseInt(item) };
        } else {
            x = false;
            tmp.y = parseInt(item);
            objectAgain.push(tmp);
        }
    });
    return objectAgain;
}

export default function ({ onData, width, height, data, readOnly, autoPlay }) {
    const [clicked, setClicked] = useState(false);
    const [way, setWay] = useState(data || [])
    const canvasRef = React.useRef(null)

    let prevX, prevY;
    function draw(ctx, location) {

        if (!prevX) {
            prevX = location.x
            prevY = location.y
            return;
        }

        ctx.strokeStyle = 'dodgerblue'
        ctx.shadowColor = 'dodgerblue'
        ctx.shadowBlur = 1;
        ctx.beginPath();
        ctx.moveTo(prevX, prevY);
        ctx.lineTo(location.x, location.y);
        prevX = location.x
        prevY = location.y

        ctx.stroke();
    }

    function getMousePos(canvas, evt) {
        var rect = canvas.getBoundingClientRect();
        return {
            x: evt.clientX - rect.left,
            y: evt.clientY - rect.top
        };
    }

    function start(width, height, ctx) {
        ctx.shadowBlur = 0
        ctx.strokeStyle = 'black'
        ctx.strokeWidth = 3
        ctx.beginPath()
        ctx.moveTo(10, height / 1.4)
        ctx.lineTo(width - 10, height / 1.4)
        ctx.stroke()
        ctx.font = "30px Arial";
        ctx.fillText("X", 12, height / 1.4 - 10);
    }

    useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        start(canvas.width, canvas.height, ctx)
    })

    function ending() {
        setClicked(false)
        prevX = prevY = undefined;
        way.push(undefined)
        setWay(way)
        if (onData) onData(way);
    }

    if (autoPlay) {
        setTimeout(_ => play(), 300);
    }

    const play = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        start(canvas.width, canvas.height, ctx)
        ctx.strokeStyle = 'dodgerblue'
        ctx.shadowColor = 'dodgerblue'
        let i = 1
        let timer = setInterval(() => {
            ctx.shadowBlur = 1;
            ctx.beginPath();
            let from = way[i - 1];
            let to = way[i];
            if (from && to) {
                ctx.moveTo(from.x, from.y);
                ctx.lineTo(to.x, to.y);
                ctx.stroke();
            }
            i++
            if (i >= way.length) {
                clearInterval(timer)
            }
        }, 20)
    }


    return (
        <div className="row">
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
                style={{ cursor: "crosshair", border: 'thin solid #e1e1e1' }}
                onMouseDown={() => setClicked(!readOnly)}
                onMouseUp={() => ending()}
                onMouseLeave={() => ending()}
                onMouseMove={e => {
                    if (!clicked) return;
                    const canvas = canvasRef.current
                    let pos = getMousePos(canvas, e)
                    way.push(pos)
                    setWay(way)
                    const ctx = canvas.getContext('2d')
                    draw(ctx, pos)
                }}
            />

            <div className="column" style={{ margin: '10px' }}>
                <button onClick={_ => play()}><span className="fa fa-play" /></button>

                {!readOnly && <button onClick={() => {
                    const canvas = canvasRef.current;
                    const ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    setWay([])
                }}><span className="fa fa-eraser" /></button>}
            </div>

        </div>
    )
}