import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import SignCanvas, { encodeSign } from "../sign-canvas/sign-canvas";
import { countries } from "countries-list";

interface IProps {
    onSubmit: (any) => void,
    useFormData: any,
    buttonText: string,
    className?: string,
    onChange?: (any) => void,
    t: any;
    children: any;
    hideSubmit?: boolean;
}

export const Form: React.FC<IProps> = (props) => {

    let { children } = props;
    let childrenAsArray: Array<ReactElement<any>> = Array.isArray(children || []) ? children : [children];

    return <BaseForm {...props}>
        {f => {
            return childrenAsArray.map(child => {
                if (!child || !child.props) return;
                return child.props.name ? f(child) : child;
            })
        }}
    </BaseForm>;
}

export const BaseForm: React.FC<IProps> = ({ useFormData, onSubmit, onChange, buttonText, className, children, t, hideSubmit }) => {
    let methods = useForm(useFormData);
    let { handleSubmit, watch } = methods;
    let classNames = ['nform', className].join(' ');
    const watchAllFields = watch();
    useEffect(() => {
        onChange?.(watchAllFields);
    }, [watchAllFields]);

    let formCounterForKeyUniqunes = 0;
    let thereIsOneRequired = false;
    if (!children) return null;
    return (
        <form className={classNames} onSubmit={handleSubmit(onSubmit || (_ => { }))}>
            {children(child => {
                if (child.props.required) {
                    thereIsOneRequired = true;
                }
                return React.createElement(child.type, {
                    ...{
                        ...child.props,
                        ...methods,
                        key: [child.props.name, formCounterForKeyUniqunes++].join('')
                    }
                })
            }, methods)}
            {!hideSubmit && <div className="nrow description">
                <input type="submit" value={buttonText} />
                {t && thereIsOneRequired && <p><span className="error">*</span>&nbsp;-&nbsp;{t('nigriv_ui_field_required')}</p>}
                <div className="divider"></div>
            </div>}
        </form>
    );
}

export function Email({ register, errors, name, placeholder, icon, label, defaultValue, required, autoFocus, autoComplete }) {
    return (
        <div className="field">
            <div className="nrow">
                {icon && (
                    <div className="icon">
                        <i className={["fa", icon].join(' ')} aria-hidden="true" />
                    </div>
                )}
                <div className="ncolumn lefted">
                    <label className="not-growing">&nbsp;{required && <span className="error">*</span>}{label}</label>
                    <input type="email" autoFocus={autoFocus} autoComplete={autoComplete} placeholder={placeholder} name={name} ref={register} defaultValue={defaultValue} required={required} />
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function Input({ register, errors, name, placeholder, icon, label, defaultValue, autoFocus, required, autoComplete, tooltip, options, optionsTitle, setValue }) {
    return (
        <div className="field">
            <div className="nrow">
                {icon && (
                    <div className="icon">
                        <i className={["fa", icon].join(' ')} aria-hidden="true" />
                    </div>
                )}
                <div className="ncolumn lefted">
                    <div className="nrow">
                        <label className="not-growing">&nbsp;{required && <span className="error">*</span>}{label}</label>
                        {tooltip && <div data-tooltip={tooltip} className="tooltip">
                            <img width="18px" height="18px" src="https://nigrivmultimedia.blob.core.windows.net/koala/icons/information-tooltip.svg" alt="Information" />
                        </div>}
                        <div className="divider"></div>
                    </div>
                    <input placeholder={placeholder} name={name} ref={register} defaultValue={defaultValue} autoFocus={autoFocus} required={required} autoComplete={autoComplete || "off"} />
                    {options && <>
                        <div className="options">
                            {optionsTitle}
                            {options.map((item, key) => <span key={key} onClick={_ => setValue(name, item)}>{item}</span>)}
                        </div>
                    </>}
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function TextArea({ register, errors, name, placeholder, icon, label, defaultValue, autoFocus, rows = 4, onChange, maxLength }) {
    return (
        <div className="field">
            <div className="nrow">
                {icon && (
                    <div className="icon">
                        <i className={["fa", icon].join(' ')} aria-hidden="true" />
                    </div>
                )}
                <div className="ncolumn lefted">
                    <label className="not-growing">&nbsp;{label}</label>
                    <textarea placeholder={placeholder} name={name} ref={register} defaultValue={defaultValue} autoFocus={autoFocus} rows={rows} onChange={onChange} maxLength={maxLength} />
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function Password({ register, errors, name, placeholder, icon, label, required, defaultValue }) {
    return (
        <div className="field">
            <div className="nrow">
                {icon && (<div className="icon">
                    <i className={["fa", icon].join(' ')} aria-hidden="true" />
                </div>)}
                <div className="ncolumn lefted">
                    <label className="not-growing">&nbsp;{required && <span className="error">*</span>}{label}</label>
                    <input type="password" placeholder={placeholder} name={name} ref={register} required={required} defaultValue={defaultValue} />
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function Slider({ register, errors, name, placeholder, icon, label, required, defaultValue, minimum = 1, maximum = 100, textRender }) {
    return (
        <div className="field">
            <div className="nrow">
                {icon && (<div className="icon">
                    <i className={["fa", icon].join(' ')} aria-hidden="true" />
                </div>)}
                <div className="ncolumn lefted">
                    <label className="not-growing">&nbsp;{required && <span className="error">*</span>}{label}</label>
                    <input type="range" min={minimum} max={maximum} placeholder={placeholder} name={name} ref={register} required={required} defaultValue={defaultValue} />
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function Number({ register, errors, name, placeholder, icon, label, required, defaultValue, minimum = 1, maximum = 100, textRender }) {
    return (
        <div className="field">
            <div className="nrow">
                {icon && (<div className="icon">
                    <i className={["fa", icon].join(' ')} aria-hidden="true" />
                </div>)}
                <div className="ncolumn lefted">
                    <label className="not-growing">&nbsp;{required && <span className="error">*</span>}{label}</label>
                    <input type="number" min={minimum} max={maximum} placeholder={placeholder} name={name} ref={register} required={required} defaultValue={defaultValue} />
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function Date({ register, errors, name, placeholder, icon, label, required, defaultValue, minimum = 1, maximum = 100, textRender }) {
    return (
        <div className="field">
            <div className="nrow">
                {icon && (<div className="icon">
                    <i className={["fa", icon].join(' ')} aria-hidden="true" />
                </div>)}
                <div className="ncolumn lefted">
                    {(label || required) && <label className="not-growing">&nbsp;{required && <span className="error">*</span>}{label}</label>}
                    <input type="date" className="ninput" min={minimum} max={maximum} placeholder={placeholder} name={name} ref={register} required={required} defaultValue={defaultValue} />
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function DateTime({ register, errors, name, icon, labelDate, labelTime, requiredDate, requiredTime, defaultValueDate, defaultValueTime }) {
    return (
        <div className="field">
            <div className="nrow">
                {icon && (<div className="icon">
                    <i className={["fa", icon].join(' ')} aria-hidden="true" />
                </div>)}
                <div className="ncolumn lefted">
                    <label className="not-growing">&nbsp;{requiredDate && <span className="error">*</span>}{labelDate}</label>
                    <input type="date" name={name + '_date'} ref={register} required={requiredDate} defaultValue={defaultValueDate} />
                    {errors && errors[name + '_date'] && <p className="error">{errors[name + '_date'].message}</p>}
                </div>
                <div className="ncolumn lefted">
                    <label className="not-growing">&nbsp;{requiredTime && <span className="error">*</span>}{labelTime}</label>
                    <input type="time" name={name + '_time'} ref={register} required={requiredTime} defaultValue={defaultValueTime} />
                    {errors && errors[name + '_time'] && <p className="error">{errors[name + '_time'].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function Select({ register, errors, name, icon, options, label, defaultValue, onChange, value }) {

    return (
        <div className="field">
            <div className="nrow">
                {icon && (<div className="icon">
                    <i className={["fa", icon].join(' ')} aria-hidden="true" />
                </div>)}
                <div className="ncolumn lefted">
                    <label className="not-growing">&nbsp;{label}</label>
                    <select onChange={onChange} name={name} ref={register} defaultValue={defaultValue} value={value}>
                        {options.map((item, key) => (
                            <option key={key} value={item.value}>{item.label}</option>
                        ))}
                    </select>
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

function splitter(str) {
    if (!str) return '';
    let result = `${str}`.replace(/\s/gmi, "").replace(/(\d{3})/gim, "$1 ");
    return result.trim();
}

export function PhoneNumber({ errors, label, defaultPrefix, defaultPhone, onChange }) {
    const [phone, setPhoneNumber] = useState([defaultPrefix, splitter(defaultPhone)]);
    useEffect(() => {
        if (!onChange) return;
        onChange(phone);
    }, [phone]);
    return (
        <div className="field">
            <label className="not-growing">&nbsp;{label}</label>
            <div className="nrow">
                <select className="nselect" onChange={e => setPhoneNumber([e.target.value, phone[1]])} defaultValue={defaultPrefix} value={phone[0]}>
                    {Object.keys(countries).sort((a, b) => `${countries[a].native}`.localeCompare(`${countries[b].native}`)).map((countryCode, key) => (
                        <option key={key} value={countries[countryCode].phone}>{countries[countryCode].emoji} {countries[countryCode].native} (+{countries[countryCode].phone})</option>
                    ))}
                </select>
                <input type="phone" value={splitter(phone[1])} onChange={e => {
                    setPhoneNumber([phone[0], e.target.value.replace(/\D/gi, '')]);
                }} />
            </div>
            {errors && errors.message && <p className="error">{errors.message}</p>}
        </div>
    )
}

export function Checkbox({ register, errors, name, label, required, defaultValue, onChange }) {
    return (
        <div className="field">
            <div className="nrow">
                <div className="ncolumn">
                    <div className="nrow">
                        <div className="ncheckbox-row">
                            <label className="nswitch not-growing">
                                <input type="checkbox" onChange={e => onChange ?? (e.target.checked)} name={name} ref={register} required={required} defaultChecked={defaultValue} />
                                <span className="nslider"></span>
                            </label>
                            <label className="not-growing">&nbsp;{required && <span className="error">*</span>}{label}</label>
                        </div>
                        <div className="divider"></div>
                    </div>
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function Upload({ register, errors, name, placeholder, accept, label, required }) {
    return (
        <div className="field">
            <div className="nrow">
                <div className="ncolumn lefted">
                    <label className="not-growing">&nbsp;{required && <span className="error">*</span>}{label}</label>
                    <input type="file" accept={accept} placeholder={placeholder} name={name} ref={register} required={required} />
                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

export function Sign({ errors, name, label, setValue, readOnly, data }) {
    errors = errors || {};
    return (
        <div className="field">
            <div className="nrow">
                <div className="icon">
                    <i className={["fa", "fa-pencil"].join(' ')} aria-hidden="true" />
                </div>
                <div className="ncolumn lefted">
                    <label>&nbsp;{label}</label>
                    <SignCanvas
                        width="300"
                        height="100"
                        readOnly={readOnly}
                        autoPlay={false}
                        data={data}
                        onData={data => setValue(name, encodeSign(data))} />

                    {errors && errors[name] && <p className="error">{errors[name].message}</p>}
                </div>
            </div>
        </div>
    )
}

