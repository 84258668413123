import React, { useState, useEffect, useRef } from "react";

export default function Heatmap({
    data = [[]],
    startColor = [255,255,255],
    endColor = [77, 154, 38],
    columnNames = (i, length) => `${i}/${length}`,
    rowNames = (i, length) => `${i}/${length}`,
    xTitle,
    yTitle,
    caption,
}) {

    let [max, setMax] = useState(data[0][0] || 1);
    const element = useRef<HTMLTableDataCellElement>(null);

    useEffect(() => {
        let tmp = max;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].length; j++) {
                tmp = Math.max(tmp, data[i][j]);
            }
        }
        setMax(tmp);
    }, [data]);

    return <div className="nheatmap">
        <div className="nrow">

            <div className="divider">
                <table>
                    {caption && <caption>{caption}</caption>}
                    <tbody>
                        <tr>
                            {yTitle && <th rowSpan={data.length + 1}>
                                <div className="nheatmap__title nrotated">{yTitle}</div>
                            </th>}
                            {(data[0] || []).map((_, columnKey) => {
                                return <th key={columnKey}>
                                    <div className="nheatmap__element">
                                        {columnNames(columnKey, data[0].length)}
                                    </div>
                                </th>;
                            })}
                            <th ref={element}></th>
                        </tr>
                        {(data || []).map((row, rowKey) => {
                            return <tr key={rowKey} className="nheatmap__row" style={{ height: `${element.current && element.current.offsetWidth}px` }}>
                                {row.map((column, columnKey) => {
                                    let color = [...startColor];
                                    color[0] += (endColor[0]-startColor[0])*column/max;
                                    color[1] += (endColor[1]-startColor[1])*column/max;
                                    color[2] += (endColor[2]-startColor[2])*column/max;
                                    return <>
                                        <td
                                            key={columnKey}
                                            className="nheatmap__column"
                                            style={{
                                                backgroundColor: `rgb(${color.join(',')})`,
                                            }}>
                                            {data[rowKey][columnKey]}
                                        </td>
                                    </>;
                                })}
                                <th>{rowNames(row.length - rowKey - 1, row.length)}</th>
                            </tr>
                        })}
                        {xTitle && <tr>
                            <th colSpan={(data || []).length + 2}>
                                <div className="nheatmap__title">{xTitle}</div>
                            </th>
                        </tr>}
                    </tbody>

                </table>
            </div>
        </div>
    </div>;
}