import React, { useState } from "react";
import { api } from "../../api/web";
import { Form } from "@procek69/nigriv-ui";

export default function NewPassword() {

    const [message, setMessage] = useState("");
    let email = sessionStorage.getItem("email");
    let challenge = sessionStorage.getItem("challenge");
    if (!email || !challenge) {
        window.location.assign('#/');
    }

    const ValidateChallenge = async data => {
        const { password, repeat_password } = data;
        if (!password) return;
        if (!repeat_password) return;
        if (password != repeat_password) {
            setMessage("Podane hasła różnią się.");
            return;
        }
        const resp = await api.ResetPassword({
            email,
            challenge,
            new_password: password,
        });
        if (resp && resp.error == "PASSWORD_COMPLEXITY") {
            setMessage("Twoje hasło jest za mało skomplikowane.\nSpróbuj ponownie używając:\n- małych, dużych liter\n- cyfr\n- znaków specjalnych\n- większej ilości znaków\n");
            return;
        }
        if (resp && resp.error == "ADMIN_CANT_RESET") {
            setMessage("Jesteś administratorem w Azure AD.\nResetowanie haseł administratorów jest zabronione.");
            return;
        }
        setMessage("");
        sessionStorage.clear();
        localStorage.clear();
        window.location.assign(resp.error ? "#/configured/failed" : "#/configured/success");
    };

    return <>
        {message && <div className="error-message text-align-left">{message.split("\n").map((el, key) => <p key={key}>{el}</p>)}</div>}
        <div className={"box" + (message ? " show-message" : "")}>
            <h2>Resetowanie hasła</h2>
            <br />
            <Form.Form onSubmit={ValidateChallenge} buttonText="Zatwierdź">
                <Form.Password label="Nowe hasło" name="password" />
                <Form.Password label="Powtórz nowe hasło" name="repeat_password" />
            </Form.Form>
        </div>
    </>;
}