const ApiDriver = require("./driver.js");

const API_HOST = "api.reset.nigrivhub.com";

module.exports = class API {
    
    constructor() {
        this._driver = new ApiDriver({ host: API_HOST });
    }
    
    AadLogin() {
        let url = this._driver.opaqueURL("/auth/aad/callback");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    AadParams() {
        let url = this._driver.opaqueURL("/auth/aad/params");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    AddToBlockListInput = ["email"];
    AddToBlockList({ email }) {
        let url = this._driver.opaqueURL("/domains/blocklist");
        return this._driver.sendPost({
            data: { email },
            endpoint: url.toString(),
        });
    }

    AddToVipListInput = ["email"];
    AddToVipList({ email }) {
        let url = this._driver.opaqueURL("/domains/viplist");
        return this._driver.sendPost({
            data: { email },
            endpoint: url.toString(),
        });
    }

    AllowAccessInput = ["identity_name", "admin_email", "role"];
    AllowAccess({ identity_name, admin_email, role }) {
        let url = this._driver.opaqueURL("/identity/access");
        return this._driver.sendPost({
            data: { identity_name, admin_email, role },
            endpoint: url.toString(),
        });
    }

    AskForUserInput = ["email"];
    AskForUser({ email }) {
        let url = this._driver.opaqueURL("/ask-for-user");
        return this._driver.sendPost({
            data: { email },
            endpoint: url.toString(),
        });
    }

    CanResetViaSms() {
        let url = this._driver.opaqueURL("/my/can-reset-by-sms");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    CheckNotifications() {
        let url = this._driver.opaqueURL("/my/notifications");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    ConfirmEmailInput = ["email", "challenge"];
    ConfirmEmail({ email, challenge }) {
        let url = this._driver.opaqueURL("/user/confirm-email");
        return this._driver.sendPost({
            data: { email, challenge },
            endpoint: url.toString(),
        });
    }

    ConfirmEmailRequestInput = ["email"];
    ConfirmEmailRequest({ email }) {
        let url = this._driver.opaqueURL("/user/confirm-email-request");
        return this._driver.sendPost({
            data: { email },
            endpoint: url.toString(),
        });
    }

    CountAadUsers() {
        let url = this._driver.opaqueURL("/aad/users/count");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    CountUsedLicenses() {
        let url = this._driver.opaqueURL("/my/used-licenses");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    CreateDomainForIdentityInput = ["domain_name", "identity_name"];
    CreateDomainForIdentity({ domain_name, identity_name }) {
        let url = this._driver.opaqueURL("/identity/domain");
        return this._driver.sendPost({
            data: { domain_name, identity_name },
            endpoint: url.toString(),
        });
    }

    CreateDomainInput = ["id_domain", "name", "id_identity"];
    CreateDomain({ id_domain, name, id_identity }) {
        let url = this._driver.opaqueURL("/domains");
        return this._driver.sendPost({
            data: { id_domain, name, id_identity },
            endpoint: url.toString(),
        });
    }

    DeleteDomainInput = ["id_domain", "name", "id_identity"];
    DeleteDomain({ id_domain, name, id_identity }) {
        let url = this._driver.opaqueURL("/domains");
        return this._driver.sendDelete({
            data: { id_domain, name, id_identity },
            endpoint: url.toString(),
        });
    }

    ListDomainInput = ["id_domain", "name", "id_identity"];
    ListDomain({ id_domain, name, id_identity }) {
        let url = this._driver.opaqueURL(["", "domains"].join("/"));
        if (id_domain) url.searchParams.set("id_domain", id_domain);
        if (name) url.searchParams.set("name", name);
        if (id_identity) url.searchParams.set("id_identity", id_identity);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateDomainInput = ["id_domain", "name", "id_identity"];
    UpdateDomain({ id_domain, name, id_identity }) {
        let url = this._driver.opaqueURL("/domains");
        return this._driver.sendPut({
            data: { id_domain, name, id_identity },
            endpoint: url.toString(),
        });
    }

    GetAllLogsOfUserInput = ["user_email"];
    GetAllLogsOfUser({ user_email }) {
        let url = this._driver.opaqueURL("/users/event");
        return this._driver.sendPost({
            data: { user_email },
            endpoint: url.toString(),
        });
    }

    GetAuthenticatorTokenInput = ["email"];
    GetAuthenticatorToken({ email }) {
        let url = this._driver.opaqueURL("/user/authenticator-token");
        return this._driver.sendPost({
            data: { email },
            endpoint: url.toString(),
        });
    }

    GetDomainByIdInput = ["id_domain"];
    GetDomainById({ id_domain }) {
        let url = this._driver.opaqueURL("/domain/by-id");
        return this._driver.sendPost({
            data: { id_domain },
            endpoint: url.toString(),
        });
    }

    GetEndOfPhoneNumberInput = ["email"];
    GetEndOfPhoneNumber({ email }) {
        let url = this._driver.opaqueURL("/user/my-phone-number");
        return this._driver.sendPost({
            data: { email },
            endpoint: url.toString(),
        });
    }

    GetMyLicenses(input) {
        let url = this._driver.opaqueURL("/my/licenses");
        return this._driver.sendPost({
            data: input,
            endpoint: url.toString(),
        });
    }

    GetResetAdminInput = ["id_domain"];
    GetResetAdmin({ id_domain }) {
        let url = this._driver.opaqueURL("/domain/get-reset-admin");
        return this._driver.sendPost({
            data: { id_domain },
            endpoint: url.toString(),
        });
    }

    GetSmsStatsForThisMonth() {
        let url = this._driver.opaqueURL("/my/sms-stats");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    GetTermsAccept() {
        let url = this._driver.opaqueURL("/my/terms-accept");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    GetUserInput = ["email"];
    GetUser({ email }) {
        let url = this._driver.opaqueURL("/my/user/by-email");
        return this._driver.sendPost({
            data: { email },
            endpoint: url.toString(),
        });
    }

    CreateIdentityInput = ["id_identity", "name"];
    CreateIdentity({ id_identity, name }) {
        let url = this._driver.opaqueURL("/identities");
        return this._driver.sendPost({
            data: { id_identity, name },
            endpoint: url.toString(),
        });
    }

    DeleteIdentityInput = ["id_identity", "name"];
    DeleteIdentity({ id_identity, name }) {
        let url = this._driver.opaqueURL("/identities");
        return this._driver.sendDelete({
            data: { id_identity, name },
            endpoint: url.toString(),
        });
    }

    IdentityExchangeInput = ["id_identity"];
    IdentityExchange({ id_identity }) {
        let url = this._driver.opaqueURL("/me/identity-exchange");
        return this._driver.sendPost({
            data: { id_identity },
            endpoint: url.toString(),
        });
    }

    ListIdentityInput = ["id_identity", "name"];
    ListIdentity({ id_identity, name }) {
        let url = this._driver.opaqueURL(["", "identities"].join("/"));
        if (id_identity) url.searchParams.set("id_identity", id_identity);
        if (name) url.searchParams.set("name", name);

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    UpdateIdentityInput = ["id_identity", "name"];
    UpdateIdentity({ id_identity, name }) {
        let url = this._driver.opaqueURL("/identities");
        return this._driver.sendPut({
            data: { id_identity, name },
            endpoint: url.toString(),
        });
    }

    LastEventsForIdentity() {
        let url = this._driver.opaqueURL("/my/identity/events");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    ListBlockListPerDomainInput = ["id_domain"];
    ListBlockListPerDomain({ id_domain }) {
        let url = this._driver.opaqueURL("/blocklist-per-domain");
        return this._driver.sendPost({
            data: { id_domain },
            endpoint: url.toString(),
        });
    }

    ListMyDomains(input) {
        let url = this._driver.opaqueURL("/my/domains");
        return this._driver.sendPost({
            data: input,
            endpoint: url.toString(),
        });
    }

    ListMyIdentities(input) {
        let url = this._driver.opaqueURL("/my/identities");
        return this._driver.sendPost({
            data: input,
            endpoint: url.toString(),
        });
    }

    ListMyUsers(input) {
        let url = this._driver.opaqueURL("/my/users");
        return this._driver.sendPost({
            data: input,
            endpoint: url.toString(),
        });
    }

    ListVipListPerDomainInput = ["id_domain"];
    ListVipListPerDomain({ id_domain }) {
        let url = this._driver.opaqueURL("/viplist-per-domain");
        return this._driver.sendPost({
            data: { id_domain },
            endpoint: url.toString(),
        });
    }

    MyEvents() {
        let url = this._driver.opaqueURL("/my/events");

        return this._driver.sendGet({
            endpoint: url.toString(),
        });
    }

    RemoveFromBlockListInput = ["email"];
    RemoveFromBlockList({ email }) {
        let url = this._driver.opaqueURL("/domains/blocklist");
        return this._driver.sendDelete({
            data: { email },
            endpoint: url.toString(),
        });
    }

    RemoveFromVipListInput = ["email"];
    RemoveFromVipList({ email }) {
        let url = this._driver.opaqueURL("/domains/viplist");
        return this._driver.sendDelete({
            data: { email },
            endpoint: url.toString(),
        });
    }

    ResetPasswordInput = ["email", "challenge", "new_password"];
    ResetPassword({ email, challenge, new_password }) {
        let url = this._driver.opaqueURL("/user/reset-password");
        return this._driver.sendPost({
            data: { email, challenge, new_password },
            endpoint: url.toString(),
        });
    }

    RevokeAccessInput = ["identity_name", "admin_email", "role"];
    RevokeAccess({ identity_name, admin_email, role }) {
        let url = this._driver.opaqueURL("/identity/access");
        return this._driver.sendDelete({
            data: { identity_name, admin_email, role },
            endpoint: url.toString(),
        });
    }

    SendResetSMSInput = ["email", "phone_number", "challenge"];
    SendResetSMS({ email, phone_number, challenge }) {
        let url = this._driver.opaqueURL("/user/send-reset-sms");
        return this._driver.sendPost({
            data: { email, phone_number, challenge },
            endpoint: url.toString(),
        });
    }

    SetLicensesOfInput = ["id_identity", "licenses"];
    SetLicensesOf({ id_identity, licenses }) {
        let url = this._driver.opaqueURL("/identity/licenses");
        return this._driver.sendPost({
            data: { id_identity, licenses },
            endpoint: url.toString(),
        });
    }

    SetNotificationsInput = ["notifications"];
    SetNotifications({ notifications }) {
        let url = this._driver.opaqueURL("/my/notifications");
        return this._driver.sendPost({
            data: { notifications },
            endpoint: url.toString(),
        });
    }

    SetPhoneNumberRequestInput = ["email", "phone_number"];
    SetPhoneNumberRequest({ email, phone_number }) {
        let url = this._driver.opaqueURL("/user/phone-number-request");
        return this._driver.sendPost({
            data: { email, phone_number },
            endpoint: url.toString(),
        });
    }

    SetResetAdminInput = ["id_domain"];
    SetResetAdmin({ id_domain }) {
        let url = this._driver.opaqueURL("/domain/set-reset-admin");
        return this._driver.sendPost({
            data: { id_domain },
            endpoint: url.toString(),
        });
    }

    SetResetMethodInput = ["email", "method"];
    SetResetMethod({ email, method }) {
        let url = this._driver.opaqueURL("/user/reset-method");
        return this._driver.sendPut({
            data: { email, method },
            endpoint: url.toString(),
        });
    }

    SyncAadDirectory(input) {
        let url = this._driver.opaqueURL("/connectors/azure/sync");
        return this._driver.sendPost({
            data: input,
            endpoint: url.toString(),
        });
    }

    TryConnectToAzure(input) {
        let url = this._driver.opaqueURL("/connectors/azure/test");
        return this._driver.sendPost({
            data: input,
            endpoint: url.toString(),
        });
    }

    UpdateDomainCustomizationInput = ["id_domain", "reset_logo", "reset_color", "reset_background", "reset_fg_color"];
    UpdateDomainCustomization({ id_domain, reset_logo, reset_color, reset_background, reset_fg_color }) {
        let url = this._driver.opaqueURL("/domains/by-id/customization");
        return this._driver.sendPost({
            data: { id_domain, reset_logo, reset_color, reset_background, reset_fg_color },
            endpoint: url.toString(),
        });
    }

    UpdateTermsAccept(input) {
        let url = this._driver.opaqueURL("/my/terms-accept");
        return this._driver.sendPost({
            data: input,
            endpoint: url.toString(),
        });
    }

    ValidateMsAuthenticatorInput = ["email", "challenge"];
    ValidateMsAuthenticator({ email, challenge }) {
        let url = this._driver.opaqueURL("/user/authenticator-token");
        return this._driver.sendPut({
            data: { email, challenge },
            endpoint: url.toString(),
        });
    }

    ValidatePhoneNumberInput = ["email", "challenge", "phone_number"];
    ValidatePhoneNumber({ email, challenge, phone_number }) {
        let url = this._driver.opaqueURL("/user/phone-number");
        return this._driver.sendPut({
            data: { email, challenge, phone_number },
            endpoint: url.toString(),
        });
    }

    WipePasswordResetInput = ["email"];
    WipePasswordReset({ email }) {
        let url = this._driver.opaqueURL("/my/user/wipe");
        return this._driver.sendPost({
            data: { email },
            endpoint: url.toString(),
        });
    }

};