import React from "react";
import { useState } from "react";
import { api } from "./../../api/web";
import { Checkbox } from "@procek69/nigriv-ui";

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export default function StartFrom() {

    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [termsAccept, setTermsAccept] = useState(false);
    const [email, setEmail] = useState("");

    const onSubmit = async () => {
        if (!validateEmail(email)) {
            setMessage("To nie jest adres email");
            return;
        }
        if (!termsAccept) {
            setMessage("Aby kontynować, zaakceptuj regulamin");
            return;
        }
        setLoading(true);
        sessionStorage.setItem("email", email);
        let interval = setInterval(() => {
            setLoading(false);
        }, 5000);
        const stateOf = (await api.AskForUser({ email })) || {};
        clearInterval(interval);
        customizeUI && customizeUI(stateOf.settings);
        setLoading(false);
        if (stateOf.is_valid == false && (stateOf.state == "SMS" || stateOf.state == "MS_AUTH")) {
            window.location.href = "#/not-configured/message";
            return;
        }
        switch (stateOf.state) {
            case "NOT_EXIST":
            case "NO_RESET_METHOD":
                window.location.href = "#/not-configured/message";
                return;
            case "NO_LICENSE":
                setMessage(`Adres ${email} nie ma licencji na użycie resetu.`);
                return;
            case "BLOCKLIST":
                setMessage(`Adres ${email} ze względów bezpieczeństwa\nnie może zresetować hasła.`)
                return;
            case "SMS":
                window.location.href = "#/configured/end-of-phone-number";
                return;
            case "MS_AUTH":
                window.location.href = "#/configured/confirm-ms-auth";
                return;
        }
    }
    return <>
        {message && <div className="error-message">{message.split("\n").map((el, key) => <p key={key}>{el}</p>)}</div>}

        <div className={"box" + (message ? " show-message" : "")}>
            {loading && <>
                <p className="loading-message">Łączenie...</p>
            </>}
            {!loading && <>
                <div className="nform">
                    <div className="field">
                        <p className="recomendation">START</p>
                        <h2>Resetowanie hasła</h2>
                        <br />
                    </div>
                    <div className="field">
                        <div className="nrow">
                            <div className="ncolumn lefted">
                                <label className="not-growing">&nbsp;E-mail</label>
                                <input type="email" autoFocus={true} autoComplete="off" value={email} onChange={e => setEmail(e.target.value)} onKeyDown={e => {
                                    if (e.key == 'Enter') {
                                        onSubmit();
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                    <Checkbox title={<>Akceptuję <a href="/pl/terms-of-use" target="_blank" className="nlink">regulamin</a></>} onChange={e => setTermsAccept(!termsAccept)} value={termsAccept} />                    
                    <br />
                    <div className="nbutton green start-button" onClick={onSubmit}>Zresetuj hasło</div>
                </div>
            </>}
        </div>
    </>;
}