import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

export function MenuItem ({ text, route, icon, location, className, children, exact }) {

    let calc = () => true;
    if (exact) {
        calc = () => window.location.hash.substring(1) == route;
    } else {
        calc = () => window.location.hash.substring(1).startsWith(route);
    }

    let [isActive, setIsActive] = useState(calc());
    useEffect(() => {
        setIsActive(calc());
    }, [location])

    let elementClassName = ["row with-items", className].join(' ');;
    if (isActive) {
        elementClassName = [elementClassName, "active"].join(' ');
    }

    return (
        <a href={['#', route].join('')} className={elementClassName}>
            <div className="icon">
                <span className={['fa fa-2x', icon].join(' ')}></span>
            </div>
            <div className="text">{text}</div>
            <div className="divider"></div>
            {children}
        </a>
    )
}

export default withRouter(MenuItem);