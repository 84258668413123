import React from "react";

export function AppStructure({className, children}) {
    return (
        <div className={['nstructure is-app', className].join(' ')}>
            {children}
        </div>
    );
}

export function Module({children, menu, flex, className}) {
    let defaultClassName = flex ? "nmodule flex" : "nmodule";
    return (
        <div className={className || defaultClassName}>
            {menu && <div className="nmodule__left_nav">{menu()}</div>}
            <div className="nmodule__body">
                {children}
            </div>
        </div>
    );
}

export function ModuleBody({children}) {
    return <div className="nmodule__body__inner">
        {children}
    </div>
}