export function readJwt(jwt) {
    if (!jwt) {
        throw new Error("There is no jwt string");
    }
    return JSON.parse(atob(jwt.split(".")[1]));
}
export function me() {
    let token = localStorage.getItem("token");
    return readJwt(token);
}
window.me = me;