import React, { useEffect, useState } from "react";
import { api } from "../../api/web";
import { Form } from "@procek69/nigriv-ui";

export default function EndOfPhoneNumber() {

    let email = sessionStorage.getItem("email");
    if (!email) {
        window.location.assign('#/');
    }
    const [phoneNumberEnd, setPhoneNumberEnd] = useState("");
    const [phoneDt, setPhoneDt] = useState([]);

    const init = async () => {
        const resp = await api.GetEndOfPhoneNumber({ email });
        setPhoneNumberEnd(resp.phone_number);
    }

    const SendResetSMS = async () => {
        if (!phoneDt[1]) return;
        await api.SendResetSMS({
            phone_number: `+${phoneDt[0]}.${phoneDt[1]}`,
            email,
        });
        sessionStorage.setItem("phone_number", `+${phoneDt[0]}.${phoneDt[1]}`);
        window.location.assign("#/configured/confirm-phone-number");
    }

    useEffect(() => {
        init();
    }, []);

    return <>
        <div className="box">
            <h2>Resetowanie hasła</h2>
            <br />
            <p>Podaj pełen numer Twojego telefonu.</p>
            <p>Podpowiedź: numer kończy się na {phoneNumberEnd}.</p>
            <Form.Form onSubmit={SendResetSMS} buttonText="Zatwierdź">
                <Form.PhoneNumber defaultPrefix="48" label="Numer telefonu" onChange={setPhoneDt} />
            </Form.Form>
        </div>
    </>;
}